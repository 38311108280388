@import "common";

.slide-template-info {
  pointer-events: auto;
  overflow-y: scroll;

  section {
    width: 100%;
    padding: 40px 0px;

    &:first-of-type {
      padding-top: 40px;
    }


    &.alt {
      background: #f0f0f0;
    }

    .inner-section {
      margin: 0 auto;
      width: 1240px;
    }
  }

  h1 {
    font-size: 25px;
  }

  h2 {
    font-size: 20px;
    margin: 0px 0px 20px 0px;
  }

  .two-columns {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 80px;
  }

  .preview-column {
    text-align: center;

    img {
      width: 100%;
      box-shadow: 2px 2px 8px rgba(0, 0, 0, .15);
    }
  }

  .title {
    font-size: 26px;
    //text-transform: uppercase;
    //letter-spacing: 1px;
    color: #333;
    font-weight: 600;
    //border-bottom: solid 3px #11a9e2;
    //display: inline-block;
    //padding-bottom: 3px;
  }

  .description-text {
    color: #333;
    font-size: 15px;
    line-height: 1.4;

    p {
      margin-bottom: 10px;
    }

    ul {
      list-style-image: url(/images/check.svg);
    }

    li {
      margin-bottom: 10px;
      font-weight: 600;
    }
  }

  .inspiration-thumbnails {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 40px;

    img {
      width: 100%;
    }
  }

  .tips {
    display: flex;
    padding-bottom: 50px;
    .tip {
      font-size: 18px;
      font-weight: 600;
      margin-right: 40px;
      text-decoration: none;
      color: #333;
      background: #f0f0f0;
      cursor: pointer;
      padding: 12px 18px;
      span {
        margin-right: 10px;
        color: $newBlue;
      }
      &:hover {
        background: $lightBlue;
      }
    }

  }
}

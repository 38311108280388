@import "common";

.user-options {
    position: absolute;
    left: 0;
    display: flex;
    height: 100%;
    opacity: 1;

    .logoContainer {
        width: 100%;
        margin-top: 12px;

        & > div {
            margin-right: 20px;
            display: inline-block;
        }
    }

    //.menu-sidebar {
    //  //width: 200px;
    //  background: #252525;
    //
    //  .back-button-item {
    //    background: #313131;
    //  }
    //}

    // Scrolling element
    .user-options-wrapper {
        overflow-y: scroll;
        overflow-x: hidden;
        width: 100%;
        height: 100%;

        // Content oflder
        .user-options-container {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;
            align-items: center;

            &.team,
            &.team-management {
                .empty-list-warning {
                    margin-top: 10px;
                    margin-bottom: 10px;
                    color: white;
                    font-weight: 600;
                    font-size: 14px;
                    display: inline-block;
                }

                .section-body {
                    width: 100%;

                    .name-title,
                    .role-title,
                    .plan-title,
                    .billing-title,
                    .status-title {
                        text-transform: uppercase;
                        font-size: 14px;
                        font-weight: 700;
                        color: white;
                        display: flex;
                        align-items: center;
                        position: relative;
                        padding: 6px 0px 6px 20px;
                        min-height: 50px;
                    }

                    .link {
                        color: white;
                        display: flex;
                        align-items: center;
                        position: relative;
                        border-bottom: 1px solid rgba(255, 255, 255, 0.07);
                        padding: 6px 0px 6px 20px;
                        min-height: 50px;
                        font-size: 12px;

                        .avatar {
                            min-width: 30px;
                        }

                        .role,
                        .members {
                            width: 250px;
                            font-size: 12px;
                            font-weight: 600;
                        }

                        .name {
                            width: 250px;

                            .fullname {
                                display: block;
                                color: white;
                            }

                            .email {
                                font-size: 12px;
                                color: #aaa;
                                display: block;
                            }
                        }

                        //.name {
                        //  width: 350px;
                        //  font-size: 14px;
                        //  font-weight: 700;
                        //}

                        .manage-team-button {
                            opacity: 0;
                            transition: opacity 200ms;
                        }

                        &:hover {
                            background: rgba(255, 255, 255, 0.05);

                            .manage-team-button {
                                opacity: 1;
                            }
                        }
                    }
                }
            }
        }
    }

    .textInput {
        width: 100%;

        label {
            display: block;
            font-size: 14px;
            color: #aaa;
        }

        input,
        input:focus {
            background: none;
            border: none;
            border-bottom: 1px solid #ccc;
            width: 100%;
            font-size: 18px;
            margin: 0;
            padding: 5px 0 5px 0;
        }

        //&.email-field {
        //  position: relative;
        //  top: 20px;
        //}
    }

    .dark-mode & {
        .textInput {
            label {
                color: #ccc;
            }

            input,
            input:focus {
                border-bottom-color: #444;
            }
        }
    }

    .manage-team {
        .textInput {
            min-width: 450px;
        }
    }

    .section-body {
        color: white;
        font-size: 18px;
        padding: 10px;
        position: relative;

        .description {
            margin-bottom: 10px;
        }

        &:not(:last-child) {
            margin-bottom: 40px;
        }
    }

    .avatar-editor {
        width: 130px;
        height: 130px;
        background-color: #333;

        .avatar {
            width: 100%;
            height: 100%;
        }

        i {
            font-size: 100px;
            position: relative;
            color: white;
        }

        .uploadIcon {
            opacity: 0;
            transition: opacity 200ms;
        }

        &:hover {
            .uploadIcon {
                opacity: 1;
            }
        }
    }

    .input-row {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;

        &.create-team {
            width: 600px;

            .create-team-button {
                margin-left: 20px;
            }
        }
    }

    .input-column {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-content: flex-start;

        //&:not(:first-child) {
        //  margin-left: 20px;
        //}
    }

    .account-info {
        //height: 150px;
        width: 250px;
        margin: 0px 30px;

        .email-verification {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: flex-start;
            text-transform: uppercase;
            font-weight: 600;
            font-size: 14px;
            margin-top: 2px;

            &.email-verified {
                color: limegreen;
            }

            &.email-not-verified {
                color: indianred;
            }

            &.email-notice-button {
                cursor: pointer;
            }
        }
    }

    .change-password {
        justify-content: flex-end;
    }

    .gdrive-notice-text, .delete-notice-text, .privacy-notice-text {
        font-size: 14px;
        width: 70%;
        margin-bottom: 20px;
        opacity: 0.8;
    }

    .spellcheck {
        .reset-text {
            margin-left: 20px;

            &.hidden {
                opacity: 0;
                transition: opacity 250ms linear;
            }

            &.fade-out {
                opacity: 1;
                transition: opacity 250ms linear;
            }
        }

        //label {
        //  text-transform: uppercase;
        //  /*
        //    Ugh. I know. The style object in our current switch has a lot of
        //    Very specific styles so the !important is unavoidable. Sorry y'all.
        //   */
        //  font-weight: 600 !important;
        //  color: white !important;
        //  margin-top: 0px !important;
        //}
    }

    .account-button {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        color: white;
        padding: 10px 20px;
        height: 45px;
        width: 250px;

        label {
            text-align: center;
            font-size: 16px;
            font-weight: 600;
            text-transform: uppercase;
            text-shadow: 0px 0px 7px rgba(0, 0, 0, 0.2);
        }

        &.disabled {
            opacity: 0.3;
            pointer-events: none;
        }

        &.google-disconnect {
            width: 320px;
        }

        &.blue {
            background: $selectionColor;

            &:hover {
                background: $darkSelectionColor;

                &.disabled {
                    opacity: 0.3;
                    pointer-events: none;
                }
            }
        }

        &.orange {
            background: #cb3d0c;

            &:hover {
                background: #872708;

                &.disabled {
                    opacity: 0.3;
                    pointer-events: none;
                }
            }
        }

        &.yellow {
            background: #fa0;

            &:hover {
                background: darken(#ffaa00, 5%);

                &.disabled {
                    opacity: 0.3;
                    pointer-events: none;
                }
            }
        }

        &.logout {
            font-size: 20px;
            font-weight: 600;
            letter-spacing: 1px;
            text-transform: uppercase;
            width: 85%;
            justify-self: center;
            align-self: flex-end;
            margin: 0 0 20px 0;
            position: absolute;
            bottom: 0;
        }
    }

    .workspace_chooser {
        color: white;
        background: rgba(0, 0, 0, 0.13);
        letter-spacing: 0.01em;
        margin: -14px;
        margin-bottom: 14px;

        .control.button {
            background: transparent;
            padding: 14px 14px 14px 16px;
            padding-left: 26px;
            padding-right: 0;
            cursor: default;
        }

        .label {
            margin-left: 3px;
            text-transform: none;
            overflow: hidden;
            text-overflow: ellipsis;
            font-size: 120%;
            white-space: normal;
        }
    }
}

.is_mobile .user-options {
    .account-info {
        width: 100%;
        margin: 0;
        margin-top: 30px;
    }
}
@import "common";

#presentationsMenu {
    height: 52px;
    .menu_button .offline-label {
        left: -126px;
    }
}

.verify-email #presentations {
    height: calc(100% - 50px);
}

.library-header {
  .MuiSelect-root{
    color: white;
  }
  .MuiSelect-icon{
    color: white;
  }
}

.is_mobile {
    #presentations {
        .presentation_menu_bar {
            left: -225px;
            transition: left 300ms;
            background: rgba(50, 50, 50, 0.96);

            &.is_visible {
                left: 0;
            }

            .logo-frame {
                position: relative;
                img {
                    margin-left: 50px;
                }
            }
        }

        .mobile-nav {
            position: relative;
            top: 15px;
            left: 15px;
            z-index: 1000;
            background: #ddd;
            border-radius: 3px;
        }
    }

    .ui-inner-pane {
        padding: 0;
    }

    .user-options {
        .ui-inner-pane {
            padding: 20px;
        }
    }

    .logo-box {
        width: 200px;
        background: transparent;
    }

    .search-bar-box {
        padding-right: 0;
        padding-top: 14px;
        padding-bottom: 14px;
        margin-top: 0;
        margin-bottom: 0;
        width: calc(100% - 130px);

        input {
            box-sizing: border-box;
            width: 100%;
            margin: 0;
        }
    }

    .with-sidebar {
        position: relative;
        background: white;

        .ui-pane-sidebar {
            display: none;
            position: absolute;
            right: 0;
            left: 0;
            top: 0;
            bottom: 0;
            z-index: 200;

            &.show {
                display: block;
            }

            .panel {
                position: absolute;
                left: 0;
                top: 0;
                bottom: 0;
                box-shadow: 1px 0 10px rgba(0, 0, 0, .5);
                overflow-y: auto;

                .library-sidebar {
                    height: auto;
                }
            }
        }
    }

    .library-list-item {
        grid-template-columns: 80px 1fr 40px;
    }

    .MuiDialog-paper {
        margin: 10px;
    }

    .MuiDialog-paperScrollPaper {
        max-height: 100%;
    }

    .share-dialog-container {
        .exit-button {
            color: white;
        }
    }

    &.dark-mode {
        .with-sidebar {
            background: #4C4E55;

            .ui-pane-sidebar {
                .panel {
                    background: #333;
                }
            }
        }
    }
}

#presentations {
    position: absolute;
    top: 0px;
    width: 100%;
    height: 100%;
    box-sizing: border-box;

    .drag_container {
        z-index: 900;
        display: block;
        position: fixed;
        img {
            margin-right: 5px;
        }
    }
    .presentations_header {
        background: #3b3d41;
        //background: #333;
        padding: 12px 120px 10px 20px;
        position: relative;
        left: 225px;
        height: 55px;
        //width: calc(100vw - 240px);
        margin-bottom: 20px;
        //margin-right: 100px;
        display: flex;
        align-items: center;

        .right-group {
            display: flex;
            width: calc(100% - 200px);
            .control .selected_item {
                border: none;
                padding-top: 2px;
                min-width: unset;
            }
            i {
                font-size: 20px;
            }
        }

        @media only screen and (max-width: 600px) {
            .right-group {
                margin: 0 auto;
            }
        }

        .search {
            pointer-events: auto;
            display: inline-block;
            flex-grow: 2;
            text-align: left;
            display: flex;
            align-items: center;
            border-radius: 25px;
            padding: 0px 6px;

            i {
                color: #ccc;
                font-size: 30px;
                margin: 0px 4px;
                vertical-align: -12px;
            }

            .clear_search {
                //color: #ccc;
                cursor: pointer;
                color: #ccc;
                vertical-align: middle;
                font-size: 24px;
            }
            input[type="text"] {
                margin: 0px 3px;
                width: calc(100% - 76px);
                vertical-align: middle;
                font-size: 18px;
                font-weight: 600;
                border: none;
                padding: 0px;
                background: none;
                outline: none;
                color: white;
            }
            transition: width 500ms;
        }

        @media only screen and (max-width: 600px) {
            .search {
                min-width: 250px;
            }
        }

        .close_button {
            display: inline-block;
            pointer-events: auto;
            width: 40px;
            height: 40px;
            color: white;
            cursor: pointer;
            text-align: center;
            &:before {
                font-family: "Material Icons";
                content: "\E5CD";
                font-size: 18px;
            }
        }

        .sync-message {
            display: inline-block;
            color: white;
        }

        .control {
            color: white;
            background: none;
            &.dropdown_menu_prompt {
                display: flex;
                align-items: center;
                .selected_item {
                    background: none;
                    //display: inline-block;
                }
                .micon {
                    color: white;
                    //vertical-align: middle;
                }
                & > i {
                    font-size: 20px;
                }
                .selected_item_label {
                    font-size: 12px;
                }
            }
        }

        .delete_button_container {
            display: flex;
            flex-grow: 1;
            &.disabled {
                pointer-events: none;
                opacity: 0.4;
            }
        }

        .delete_all_button {
            background: #404040;
            padding: 8px 10px;
            text-transform: uppercase;
            cursor: pointer;
            color: white;
            font-size: 13px;
            font-weight: 600;
            letter-spacing: 2px;
            display: flex;
            align-items: center;
            z-index: 3;
            i {
                font-size: 16px;
                color: white;
                margin-right: 8px;
            }
            label {
                cursor: pointer;
            }
            transition: transform 400ms cubic-bezier(0.555, 0.445, 0.065, 1);
            &:hover {
                color: $selectionColor;
                i {
                    color: $selectionColor;
                }
            }
        }
    }

    .error {
        color: white;
        text-align: center;
        font-size: 20px;
        font-weight: 600;
        padding-top: 80px;

        .error_message {
            margin-top: 30px;
            font-size: 16px;
        }
    }

    @media only screen and (max-width: 600px) {
        .presentations_header {
            padding: 0;
            margin: 5px auto 15px;
            width: 100%;
            left: 0;
        }
    }

    .presentation_list {
        //clear: both;
        width: calc(100% - 250px);
        height: calc(100% - 75px);
        //padding: 30px 30px 0px;
        //margin: 0px -30px -30px 0px;
        left: 250px;
        position: absolute;
        overflow-y: auto;
        overflow-x: hidden;
        .drag-rect {
            position: absolute;
            z-index: 100;
            border: 1px dotted #555;
            background: $selectionColor;
            opacity: 0.25;
        }
    }

    .presentation_list_mobile {
        left: 0;
        width: 100%;
    }

    .mobile-message {
        color: white;
        width: calc(100% - 100px);
        text-align: left;
        position: relative;
        left: 50px;
        right: 50px;
        top: -15px;
    }

    .new-presentation-button {
        pointer-events: auto;
        position: absolute;
        border: dashed 1px $selectionColor;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: 15px;
        font-weight: 600;
        text-transform: uppercase;
        color: white;
        background: rgba(255, 255, 255, 0.05);
        i {
            font-size: 40px;
            margin-bottom: 6px;
        }
        &:hover {
            background: $selectionColor;
            cursor: pointer;
        }
    }

    .presentation_item_grid {
        cursor: pointer;
        position: absolute;
        //overflow: hidden; disabled because was preventing outline from showing when thumbnail was selected
        .thumbnail {
            display: block;
            position: relative;
            width: 100%;
            &.isDragging {
                z-index: 400;
                transition: 0ms !important;
                .slide-num {
                    display: none;
                }
            }
            &.isDragFollow {
                z-index: 399;
            }
            background: unset !important;
            margin-bottom: 10px;
            box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
            img {
                width: 100%;
                height: 100%;
                vertical-align: middle;
                &.selected {
                    outline: solid 4px $selectionColor;
                }
            }

            &.loading {
                background: #222 !important;
                height: 100%;
            }
        }
        label {
            display: block;
            width: 100%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            text-align: center;
            color: #f1f1f1;
            font-size: 15px;
            font-weight: 500;
            line-height: 1.3;
            min-height: 19px;
        }
        &:hover, &.test-hover {
            .thumbnail {
                //outline: $selectionColor 3px solid;
            }
            .options_menu_button {
                display: block;
                opacity: 1;
            }
        }
        &:last-of-type {
            margin-bottom: 50px; //add scroll padding
        }
        @media only screen and (max-width: 736px) {
            .options_menu_button {
                display: block;
            }
        }
        .options_menu_button {
            background: white;
            right: 0px;
            &:before {
                color: #333;
            }
        }

        .presentation-icon {
            z-index: 1;
            position: absolute;
            margin: 5px;
            top: 0;
            right: 0;
        }

        .presentation-icon i {
            position: absolute;
            top: 0;
            right: 0;
            opacity: 0;
            transition: opacity 0.33s ease-in;
        }

        .sync-icon,
        .queued-icon,
        .resync-icon {
            color: #ffffff;
            background: #50bbe6;
            border-radius: 100%;
        }

        .complete-icon,
        .complete-icon-static {
            color: #ffffff;
            background: #33ba68;
            border-radius: 100%;
            transition: none;
        }

        i.complete-icon-static {
            display: none;
            opacity: 1;
        }

        .warning-icon {
            color: #ffb500;
        }

        i.resync-icon {
            opacity: 1;
            display: none;
            font-size: 22px;
            padding: 0 1px 2px 1px;
        }

        &.sync .sync-icon,
        &.warning .warning-icon {
            opacity: 1;
        }

        &.queued .queued-icon {
            opacity: 0.5;
        }

        &.sync .sync-icon {
            -webkit-animation: rotation 1.67s infinite linear;
        }

        &.complete .complete-icon {
            -webkit-animation: popFade 0.67s ease-in-out;
        }

        .presentation-icon:hover {
            .resync-icon {
                display: block;
            }
            .queued-icon,
            .warning-icon,
            .sync-icon {
                display: none;
            }
        }

        &.complete-static:hover .presentation-icon {
            .resync-icon {
                display: none;
            }
            .complete-icon-static {
                display: block;
            }
        }
    }

    .permissions {
        .playIcon,
        .sharedIcon,
        .syncIcon {
            position: absolute;
            padding: 5px;
            z-index: 1;
        }
        &:before {
            z-index: 1;
            content: "";
            color: #ffffff;
            background: none;
            font-size: 25px;
            font-weight: bold;
            padding: 0;
            position: absolute;
            top: 0;
            left: 0;
            text-align: center;
            width: 0;
            height: 0;
            border-left: 40px solid transparent;
            border-right: 40px solid transparent;
            border-bottom: 40px solid $selectionColor;
            transform: rotate(-45deg) translate(-14.2px, -22.5px);
            //box-shadow: 3px 5px 10px rgba(0, 0, 0, 0.1);
        }
    }

    .presentation_menu_bar {
        background: rgba(0, 0, 0, .25);
        //width: 220px;
        width: 225px;
        height: 100%;
        position: absolute;
        top: 0;
        //border-left: solid 6px $newBlue;
        //border-right: solid 1px rgba(255, 255, 255, 0.1);
        //box-shadow: 6px 0px 30px rgba(0, 0, 0, .2);
        //padding-top: 18px;
        padding: 0px 0px;
        display: flex;
        flex-direction: column;
        z-index: 1;

        .logo-frame {
            width: 100%;
            height: 58px;
            background: rgba(0,0,0,.25);
            display: flex;
            align-items: center;
            img {
                margin-left: 27px;
                height: 20px;
            }
        }

        .folders_container {
            //max-height: calc(100vh - 320px);
            //overflow-y: auto;
            margin-bottom: 10px;
        }

        .divider {
            //border-top: solid 1px rgba(255,255,255,0.1);
            width: 100%;
            //margin: 10px 0px;
            height: 20px;
        }

        .workspace_chooser {
            color: white;
            background: rgba(0, 0, 0, 0.13);
            letter-spacing: 0.01em;

            .control.button {
                background: transparent;
                padding: 14px 14px 14px 16px;
                padding-left: 26px;
                padding-right: 0;
            }

            label {
                margin-left: 3px;
                text-transform: none;
                overflow: hidden;
                text-overflow: ellipsis;
                font-size: 120%;
                white-space: normal;
            }
        }

        .filter_container {
            padding: 10px 14px 14px 14px;
            flex-grow: 2;
            display: flex;
            flex-direction: column;
            height: calc(100% - 115px);
            overflow-x: hidden;
        }
        .filters {
            .presentation_menu_item:last-child {
                margin-bottom: 0px;
            }
        }

        .menu_button_container {
            position: relative;

            .probadge_container {
                position: absolute;
                left: 95px;
                top: 2px;
                opacity: 0;
                transition: opacity 300ms;
            }

            &.no-folders,
            &:hover {
                .label,
                .probadge_container {
                    opacity: 1;
                }
            }
        }

        .new_folder_button {
            position: relative;
            //background: #404040;
            background: rgba(0, 0, 0, 0.2);
            color: white;
            //background: #ddd;
            display: flex;
            align-items: center;
            //justify-content: center;
            //margin-left: 20px;
            padding: 0px;
            width: 24px;
            height: 24px;
            border-radius: 100px;
            i {
                margin-left: 4px;
                display: block;
                font-size: 16px;
                position: relative;
                top: 0px;
                color: white;
            }
            .label {
                opacity: 0;
                position: absolute;
                width: 170px;
                left: 32px;
                color: white;
                font-size: 10px;
                text-transform: uppercase;
                transition: opacity 300ms;
            }
            transition: background 300ms;
            &:hover {
                background: $selectionColor;
                color: white;
            }
        }

        .trash {
            width: 100%;
            margin: 7px 0px 15px 10px;
            display: flex;
            align-items: center;
            .label {
                text-transform: uppercase;
            }
            i {
                display: block;
                font-size: 20px;
                margin-right: 6px;
            }
        }
    }

    .trash-warning {
        border-top: 1px solid;
        padding: 10px 0;
        bottom: 0;
        color: white;
        position: absolute;
        left: 240px;
        white-space: nowrap;
        width: 100%;
        font-size: 16px;
    }
}

@-webkit-keyframes rotation {
    from {
        -webkit-transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(-359deg);
    }
}

@-webkit-keyframes popFade {
    0% {
        -webkit-transform: scale(1);
        opacity: 0;
    }
    25% {
        -webkit-transform: scale(1.25);
        opacity: 1;
    }
    100% {
        -webkit-transform: scale(0.5);
        opacity: 0;
    }
}
